<template>
    <!-- <div class="attractive-link-container">
        <div v-if="pageConfig.elements" class="attractive-link-corp">
            <img :src="pageConfig.elements[2].src" />
            <span>{{ pageConfig.elements[0].ctn }}</span>
        </div>
        <div class="attractive-link-banner">
            <img src="https://commonresource-1252524126.cdn.xiaoeknow.com/image/l6oop0sz0yt6.png" mode="aspectFill" />
        </div>
        <div v-if="pageConfig.elements" class="attractive-link-traffic">
            <div class="attractive-link-description">
                <img src="https://commonresource-1252524126.cdn.xiaoeknow.com/image/l6ornhk101al.png" />
                <div>
                    <div>扫码添加我的微信</div>
                    <div>为您定制更多专属服务</div>
                </div>
                <img src="https://commonresource-1252524126.cdn.xiaoeknow.com/image/l6ornhkb0nj2.png" />
            </div>
            <div class="attractive-link-qrcode">
                <img :src="pageConfig.elements[3].elements[0].src" />
                <img :src="pageConfig.elements[3].elements[1].src" class="attractive-link-qrcode__logo" />
            </div>
            <div class="attractive-link-tip">
                <span>若长按无法识别，可保存二维码使用微信扫一扫</span>
            </div>
        </div>
    </div> -->
    <channel-live-code :isResqueset="false"></channel-live-code>
</template>

<script>
import ChannelLiveCode from '@/views/channel_liveCode/channelCodeMiddlePage.vue';
export default {
    name: 'customElement',
    components: {
        ChannelLiveCode
    },

    props: {
        pageConfig: {
            type: Object,
            default() {
                return {};
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.attractive-link-container {
    .attractive-link-corp {
        margin: 18px 0;
        text-align: center;
        img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            vertical-align: middle;
        }
        span {
            line-height: 24px;
            font-size: 14px;
            color: #333;
            vertical-align: middle;
        }
    }
    .attractive-link-banner {
        margin-bottom: 24px;
        width: 100%;
        img {
            width: 100%;
        }
    }
    .attractive-link-traffic {
        .attractive-link-description {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 16px;
            > div {
                margin: 0 24px;
                line-height: 24px;
                font-size: 16px;
                text-align: center;
            }
            > img {
                width: 30px;
                height: 6px;
            }
        }
        .attractive-link-qrcode {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 16px;
            img {
                width: 100px;
                height: 100px;
                border: 4px solid #ff993a;
                border-radius: 4px;
            }
            .attractive-link-qrcode__logo {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 18px;
                height: 18px;
                border: none;
            }
        }
        .attractive-link-tip {
            text-align: center;
            line-height: 12px;
            font-size: 10px;
            color: #999;
        }
    }
}
</style>
